<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5">Create Outlet</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-autocomplete dense clearable label="Chain" :items="chains" :error-messages="errors.chain" v-model="object.chain"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Name*" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Unit No.*" :error-messages="errors.unit_number" v-model="object.unit_number"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Latitude" :rules="rules.latitude" :error-messages="errors.latitude" v-model="object.latitude"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Longitude" :rules="rules.longitude" :error-messages="errors.longitude" v-model="object.longitude"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Person In Charge" :error-messages="errors.contact_person" v-model="object.contact_person"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Contact Number" :error-messages="errors.contact_number" v-model="object.contact_number"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'OutletCreateDialog',
	props: {
        chains: {
            type: Array,
            required: false,
            default: () => []
        },
        address: {
            type: Object,
            required: false,
            default: () => {}
        }
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                chain: '',
                name: '',
                unit_number: '',
                latitude: '',
                longitude: '',
                contact_person: '',
                contact_number: ''
			},
			rules: {
                name: [
                    (value) => !!value || 'Name is required'
                ],
                unit_number: [
                    (value) => !!value || 'Unit number is required'
                ],
                latitude: [
                    (value) => {
                        if(value && isNaN(value)) {
                            return 'Latitude must a numeric value'
                        }

                        return true
                    }
                ],
                longitude: [
                    (value) => {
                        if(value && isNaN(value)) {
                            return 'Longitude must a numeric value'
                        }

                        return true
                    }
                ]
            },
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
            this.dialog = false
			this.loading = false
            this.object = { chain: '', name: '', unit_number: '', latitude: '', longitude: '', contact_person: '',
                            contact_number: '' }
			this.errors = {}
			this.form.resetValidation()
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object)

				this.$store.dispatch('epanel/outlet/createAddressOutlet', { id: this.$route.params.id, object: object}).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		}
	},
    watch: {
        "address": function(newValue) {
            if(newValue) {
                this.object.name = newValue.name?newValue.name:''
                this.object.latitude = newValue.latitude?newValue.latitude:''
                this.object.longitude = newValue.longitude?newValue.longitude:''
            }
        }
    }
}

</script>