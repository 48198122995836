<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Address Outlet Management</p>
                <p class="text-title mb-0">You do not have permission to view all outlets for address</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <p class="text-h6 mb-4">Address Info</p>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right">
                                <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" @click="back"><v-icon>mdi-arrow-left</v-icon></v-btn></template><span>Back</span></v-tooltip>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field dense readonly label="District" v-model="object.district"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field dense readonly label="Latitude" v-model="object.latitude"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field dense readonly label="Longitude" v-model="object.longitude"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <v-text-field dense readonly label="Address" v-model="object.address"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" v-if="chains.length > 0">
                                <v-autocomplete dense clearable label="Chain" :items="chains" v-model="filters.chain"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense clearable label="Name" v-model="filters.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_add">
                <v-col cols="6" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" @click='openCreateDialog'>
                        <v-icon small left>mdi-plus</v-icon> Create Outlet
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.chain.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.unit_number"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.latitude"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.longitude"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.contact_person"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.contact_number"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="openUpdateDialog(item, index)" v-if="item.can_edit">
                                                <v-list-item-title>Edit Outlet</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openDeleteDialog(item)" v-if="item.can_delete">
                                                <v-list-item-title>Delete Outlet</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row v-if="!loading && pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                </v-col>
            </v-row>
        </template>
		<create-dialog ref="createDialog" :chains="chains" :address="object" @created="updateItems"></create-dialog>
		<update-dialog ref="updateDialog" :chains="chains" @updated="updateItem"></update-dialog>
		<delete-dialog ref="deleteDialog" @deleted="updateItems"></delete-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'

import MessageNotifier from '../../../../shared/MessageNotifier.vue'
import OutletCreateDialog from './OutletCreateDialog.vue'
import OutletUpdateDialog from './OutletUpdateDialog.vue'
import OutletDeleteDialog from './OutletDeleteDialog.vue'

export default {
	name: 'OutletList',
	components: {
        messageNotifier: MessageNotifier,
		createDialog: OutletCreateDialog,
		updateDialog: OutletUpdateDialog,
        deleteDialog: OutletDeleteDialog
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			filters: {
				chain: '',
				name: '',
				page: 1
			},
            object: {
                district: '',
                address: '',
                latitude: '',
                longitude: ''
            },
            permissions: {
                can_list: false,
                can_add: false,
            },
			chains: [],
			items: [],
			headers: [],
			pagination: {}
		}
	},
	computed: {
        createDialog() {
			return this.$refs.createDialog
		},
		updateDialog() {
			return this.$refs.updateDialog
		},
        deleteDialog() {
            return this.$refs.deleteDialog
        },
		notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
        back: function() {
            this.$router.replace('/epanel/store/address')
        },
        get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/outlet/getAddressOutlets', { id: this.$route.params.id, filters: this.filters }).then((response) => {
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.object = response.data.object
                this.chains = response.data.chains
                this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { chain: '', name: '', page: 1}
		},
		updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
		},
		updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
		},
        openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
        },
        openUpdateDialog: function(item, index) {
            if(item.can_edit){
                this.selectedIndex = index
                this.updateDialog.updateObject(copyObject(item, ['can_edit', 'can_delete']))
                this.updateDialog.open()
            }
        },
        openDeleteDialog: function(item) {
            if(item.can_delete){
                this.deleteDialog.updateObject({id: item.id})
                this.deleteDialog.open()
            }
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        hasMenu: function(item) {
            return item.can_edit || item.can_delete
        }
	}
}

</script>